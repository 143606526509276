@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
iframe {
  pointer-events: none;
}

h6 {
  font-size: 20%;
  font-weight: normal;
}
* > * {
    font-family: "Poppins", cursive;
    
    margin: 0;
}

:root{
    --color-dark: #2C3131;
    --color-light: #F3F3F2;
}



.active{
    background-color: #2C3131;
    background-color: var(--color-dark);
    color: #F3F3F2;
    color: var(--color-light);
}



.text-dark{
    color: #2C3131;
    color: var(--color-dark);
}

.web3modal-modal-card {
    border: 1px solid grey;
    border-radius: 1.5em;
}

.walletconnect-modal__base {
    background-color: #282323 !important;
}

.walletconnect-qrcode__text,
.walletconnect-connect__button__text,
.walletconnect-modal__footer a {
    color: #FFFFFF !important;
}

.walletconnect-qrcode__text + div {
    background-color: #FFFFFF !important;
    padding-top: 1em;
    padding-bottom: 0.5em;
}

.walletconnect-modal__mobile__toggle a {
    color: #78797d !important;
}

@media only screen and (max-width: 600px) {
    .MuiTabs-flexContainer > a {
        min-width: 80px;
    }
}
