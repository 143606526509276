@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* > * {
    font-family: "Poppins", cursive;
    
    margin: 0;
}

:root{
    --color-dark: #2C3131;
    --color-light: #F3F3F2;
}



.active{
    background-color: var(--color-dark);
    color: var(--color-light);
}



.text-dark{
    color: var(--color-dark);
}

.web3modal-modal-card {
    border: 1px solid grey;
    border-radius: 1.5em;
}

.walletconnect-modal__base {
    background-color: #282323 !important;
}

.walletconnect-qrcode__text,
.walletconnect-connect__button__text,
.walletconnect-modal__footer a {
    color: #FFFFFF !important;
}

.walletconnect-qrcode__text + div {
    background-color: #FFFFFF !important;
    padding-top: 1em;
    padding-bottom: 0.5em;
}

.walletconnect-modal__mobile__toggle a {
    color: #78797d !important;
}

@media only screen and (max-width: 600px) {
    .MuiTabs-flexContainer > a {
        min-width: 80px;
    }
}